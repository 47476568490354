var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"LegosHQ",staticClass:"tw-h-full tw-overflow-y-visible md:tw-overflow-y-scroll tw-p-0 md:tw-p-10 tw-flex tw-flex-col"},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('div',{staticClass:"tw-hidden md:tw-block tw-text-2xl tw-font-bold tw-pb-4"},[_vm._v(" Commands ")]),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-y-2 md:tw-gap-x-4 tw-pb-3 md:tw-pb-0 md:tw-flex-row md:tw-sticky md:tw--top-10 tw-bg-white tw-z-10 tw-mb-0 md:tw-mb-5 md:tw-items-center"},[_c('Topbar',{staticClass:"tw-flex-initial",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),_c('div',{staticClass:"tw-flex-auto"}),(_vm.isOrgAdmin)?_c('div',{staticClass:"tw-px-2 md:tw-px-0 tw-flex tw-flex-row tw-gap-x-3"},[_c('div',[_c('AddResourceBtn',{attrs:{"primaryBtn":false},on:{"refreshResources":_vm.refreshSyncCreds}})],1),_c('div',[_c('b-dropdown',{attrs:{"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('b-button',{staticClass:"is-small",attrs:{"label":"Commands","type":"is-primary is-light","icon-left":"sync-alt","icon-right":active ? 'caret-up' : 'caret-down'}})]}}],null,false,846354163)},_vm._l((_vm.creds),function(c){return _c('b-dropdown-item',{key:c.credsId,attrs:{"aria-role":"listitem"},on:{"click":function($event){_vm.syncSidePanelCred = c;
              _vm.isSyncSidePanelOpen = true;}}},[_vm._v(" "+_vm._s(_vm.credDisplayName(c))+" ")])}),1)],1),_c('div',[_c('b-tooltip',{attrs:{"label":"Create New Directory","type":"is-primary","position":"is-top"}},[(_vm.$store.state.activeOrg.role == 'admin')?_c('a',{on:{"click":function($event){_vm.isCreateDirectorySidePanelActive = true}}},[_c('b-icon',{staticClass:"hover:tw-text-purple-500 tw-text-gray-400 tw-pt-4",attrs:{"icon":"folder-plus","size":"is-small"}})],1):_vm._e()])],1)]):_vm._e(),_c('b-field',{staticClass:"tw-w-full md:tw-w-auto md:tw-self-center md:tw-place-self-end md:tw-justify-self-end tw-px-2 md:tw-px-0"},[_c('b-input',{attrs:{"placeholder":"Search","icon":"search","size":"is-small"},model:{value:(_vm.debouncedSearchString),callback:function ($$v) {_vm.debouncedSearchString=$$v},expression:"debouncedSearchString"}})],1)],1),_c('div',{staticClass:"tw-overflow-y-scroll md:tw-overflow-y-visible tw-flex-auto",attrs:{"id":_vm._htmlSafe("legos-hq")}},[_c('div',[(_vm.activeTab === 'recent')?_c('div',[_c('b-table',{attrs:{"mobile-cards":false,"data":_vm.filteredLibraryFlatRecents,"hoverable":true,"row-class":function (row, index) {
              return 'tw-cursor-pointer';
            }}},[_c('b-table-column',{attrs:{"field":"dirName","label":"Name","cell-class":"tw-relative height41"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center",attrs:{"to":{
                name: 'kitBuilderRoute',
                query: { id: props.row.kitId.toString() },
                params: { orgId: props.row.orgId },
              }}},[_c('div',{staticClass:"tw-flex-none tw-flex tw-flex-row tw-items-center"},[(props.row.kitOrDir === 'dir')?_c('b-icon',{staticClass:"tw-text-yellow-300",attrs:{"icon":_vm._lfind(
                      _vm.openDirectories,
                      function (el) { return el === props.row.kitDirId; }
                    ) !== undefined
                      ? 'folder-open'
                      : 'folder',"size":"is-small","icon-pack":"far"}}):_c('b-icon',{staticClass:"tw-text-gray-300",attrs:{"icon":"file-code","size":"is-small","icon-pack":"far"}})],1),_c('div',{staticClass:"tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden"},[_vm._v(" "+_vm._s(props.row.name)+" ")]),_c('div',{staticClass:"tw-flex-auto"}),_c('div',{staticClass:"tw-flex-none md:tw-hidden"},[_c('b-button',{staticClass:"is-small",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(props.row)}}},[_vm._v("Share")])],1)])]}}],null,false,2830791361)}),_c('b-table-column',{attrs:{"cell-class":"tw-hidden md:tw-table-cell tw-relative","header-class":"tw-hidden md:tw-table-cell","field":"dirName","label":_vm.isOrgAdmin ? 'Code Name' : 'Description'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden",class:_vm.isOrgAdmin ? 'tw-font-mono tw-text-sm' : '',attrs:{"to":{
                name: 'kitBuilderRoute',
                query: { id: props.row.kitId.toString() },
                params: { orgId: props.row.orgId },
              }}},[(_vm.isOrgAdmin)?_c('div',{staticClass:"tw-text-gray-400 hover:tw-text-gray-400"},[_vm._v(" "+_vm._s(props.row.cloudName)+" ")]):_c('MarkdownDisplay',{staticClass:"tw-text-gray-500 hover:tw-text-gray-500",attrs:{"markdown":props.row.description,"inline":true}},[_vm._v(" "+_vm._s(props.row.description)+" ")]),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-x-3 tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-justify-end"},[(props.row.isLegoKit && !_vm.isCopycatKit(props.row))?_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"type":"is-primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.configureKitOrDir(props.row)}}},[_vm._v("Configure")]):_vm._e(),_c('div',[_c('b-button',{staticClass:"is-small show-on-hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(props.row)}}},[_vm._v("Share")])],1),(!_vm.isCopycatKit(props.row))?_c('div',[_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.hideKit(props.row)}}})],1):_vm._e()],1)],1)]}}],null,false,1137588556)})],1)],1):(_vm.activeTab === 'my-legos')?_c('div',[_c('b-table',{attrs:{"detailed":"","custom-detail-row":"","show-detail-icon":false,"mobile-cards":false,"detail-key":"kitDirId","opened-detailed":_vm.openDirectories,"data":_vm.filteredLibraryNestedDirs,"row-class":function (row, index) {
              return row.kitOrDir === 'kit' ? 'tw-cursor-pointer' : '';
            }},scopedSlots:_vm._u([{key:"detail",fn:function(props){return _vm._l((props.row.commands),function(command){return _c('tr',{key:command.kitId,staticClass:"tw-cursor-pointer"},[_c('td',{staticClass:"tw-relative",staticStyle:{"height":"37px"}},[_c('router-link',{staticClass:"tw-top-0 tw-left-0 tw-absolute tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center",attrs:{"to":{
                    name: 'kitBuilderRoute',
                    query: { id: command.kitId.toString() },
                    params: { orgId: command.orgId },
                  }}},[_c('div',{staticClass:"tw-flex-none",staticStyle:{"width":"16px"}}),_c('div',{staticClass:"tw-flex-none",staticStyle:{"width":"16px"}}),_c('div',{staticClass:"tw-flex-none tw-flex tw-flex-row tw-items-center"},[_c('b-icon',{staticClass:"tw-text-gray-300",attrs:{"icon":"file-code","size":"is-small","icon-pack":"far"}})],1),_c('div',{staticClass:"tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden"},[_vm._v(" "+_vm._s(command.name)+" ")]),_c('div',{staticClass:"tw-flex-auto"}),_c('div',{staticClass:"tw-flex-none md:tw-hidden"},[_c('b-button',{staticClass:"is-small",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(command)}}},[_vm._v("Share")])],1)])],1),_c('td',{staticClass:"tw-hidden md:tw-table-cell tw-relative"},[_c('router-link',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-px-3 tw-flex tw-flex-row tw-items-center tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden",attrs:{"to":{
                    name: 'kitBuilderRoute',
                    query: { id: command.kitId.toString() },
                    params: { orgId: command.orgId },
                  }}},[(_vm.isOrgAdmin)?_c('div',{staticClass:"tw-font-mono tw-text-sm tw-text-gray-400 hover:tw-text-gray-400"},[_vm._v(" "+_vm._s(command.cloudName)+" ")]):_c('MarkdownDisplay',{staticClass:"tw-text-gray-500 hover:tw-text-gray-500",attrs:{"markdown":command.description,"inline":true}}),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-x-3 tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-justify-end"},[(command.isLegoKit && !_vm.isCopycatKit(command))?_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"type":"is-primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.configureKitOrDir(command)}}},[_vm._v("Configure")]):_vm._e(),_c('div',[_c('b-button',{staticClass:"is-small show-on-hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(command)}}},[_vm._v("Share")])],1),(!_vm.isCopycatKit(command))?_c('div',[_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.hideKit(command)}}})],1):_vm._e()],1)],1)],1)])})}}])},[_c('b-table-column',{attrs:{"label":"Name","cell-class":"tw-relative height41"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c(props.row.kitOrDir === 'kit' ? 'router-link' : 'span',{tag:"component",staticClass:"tw-absolute tw-top-0 tw-left-0 tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-flex tw-flex-row tw-gap-x-2 tw-items-center",attrs:{"to":props.row.kitOrDir === 'kit'
                  ? {
                      name: 'kitBuilderRoute',
                      query: { id: props.row.kitId.toString() },
                      params: { orgId: props.row.orgId },
                    }
                  : null}},[_c('div',{staticClass:"hover:tw-cursor-pointer tw-flex-none tw-flex tw-flex-row tw-items-center",on:{"click":function($event){return _vm.toggleDir(props.row)}}},[_c('b-icon',{staticClass:"tw-text-gray-500",class:props.row.kitOrDir === 'kit' ? 'tw-invisible' : '',attrs:{"icon":_vm._lfind(
                      _vm.openDirectories,
                      function (el) { return el === props.row.kitDirId; }
                    ) !== undefined
                      ? 'caret-down'
                      : 'caret-right',"size":"is-small"}})],1),_c('div',{staticClass:"tw-flex-none tw-flex tw-flex-row tw-items-center"},[(props.row.kitOrDir === 'dir')?_c('b-icon',{staticClass:"tw-text-yellow-300",attrs:{"icon":_vm._lfind(
                      _vm.openDirectories,
                      function (el) { return el === props.row.kitDirId; }
                    ) !== undefined
                      ? 'folder-open'
                      : 'folder',"size":"is-small","icon-pack":"far"}}):_c('b-icon',{staticClass:"tw-text-gray-300",attrs:{"icon":"file-code","size":"is-small","icon-pack":"far"}})],1),_c('div',{staticClass:"tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden"},[_vm._v(" "+_vm._s(props.row.kitDirName)+" ")]),_c('div',{staticClass:"tw-flex-auto"}),_c('div',{staticClass:"tw-flex-none md:tw-hidden"},[_c('b-button',{staticClass:"is-small",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(props.row)}}},[_vm._v("Share")])],1)])]}}])}),_c('b-table-column',{attrs:{"cell-class":"tw-hidden md:tw-table-cell tw-relative","header-class":"tw-hidden md:tw-table-cell","label":_vm.isOrgAdmin ? 'Code Name' : 'Description'},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c(props.row.kitOrDir === 'kit' ? 'router-link' : 'span',{tag:"component",staticClass:"tw-top-0 tw-left-0 tw-px-3 tw-h-full tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-absolute tw-flex tw-flex-row tw-items-center",attrs:{"to":props.row.kitOrDir === 'kit'
                  ? {
                      name: 'kitBuilderRoute',
                      query: { id: props.row.kitId.toString() },
                      params: { orgId: props.row.orgId },
                    }
                  : null}},[(_vm.isOrgAdmin)?_c('div',{staticClass:"tw-font-mono tw-text-sm tw-text-gray-400 hover:tw-text-gray-400"},[_vm._v(" "+_vm._s(props.row.cloudName)+" ")]):_c('MarkdownDisplay',{staticClass:"tw-text-gray-500 hover:tw-text-gray-500",attrs:{"markdown":props.row.description,"inline":true}}),_c('div',{staticClass:"tw-flex tw-items-center tw-gap-x-3 tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-justify-end"},[(props.row.isLegoKit && !_vm.isCopycatKit(props.row))?_c('b-button',{staticClass:"show-on-hover is-small",attrs:{"type":"is-primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.configureKitOrDir(props.row)}}},[_vm._v("Configure")]):_vm._e(),_c('div',[_c('b-button',{staticClass:"is-small show-on-hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.shareKitOrDir(props.row)}}},[_vm._v("Share")])],1),(!_vm.isCopycatKit(props.row))?_c('div',[_c('b-button',{staticClass:"is-small",class:props.row.kitOrDir === 'dir'
                        ? 'tw-invisible'
                        : 'show-on-hover',attrs:{"icon-left":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.hideKit(props.row)}}})],1):_vm._e()],1)],1)]}}])})],1)],1):_vm._e()])]),(_vm.isShareKitPanelActive)?_c('UltimateShareSidePanel',{attrs:{"kitId":_vm.shareKitModalKit.kitId,"isCopycatKit":_vm._pytruthy(_vm.shareKitModalKit.ogKitId),"kitName":_vm.shareKitModalKit.name,"kitVisibility":_vm.shareKitModalKit.visibility,"directoryId":_vm.shareDirectoryId,"shareType":_vm.shareType},on:{"close":function($event){_vm.isShareKitPanelActive = false;
      _vm.shareType = null;
      _vm.shareKitModalKit = {};
      _vm.shareDirectoryId = null;},"emitUpdateKitVisibility":_vm.onUSSPVisibilityUpdate}}):_vm._e(),(_vm.isCreateDirectorySidePanelActive)?_c('CreateDirectorySidePanel',{attrs:{"kits":_vm.skinnyKits,"directoryOnly":false},on:{"emitCreatedDirectory":_vm.handleNewDirectory,"close":function($event){_vm.isCreateDirectorySidePanelActive = false}}}):_vm._e(),(_vm.isSyncSidePanelOpen)?_c('SyncSidePanel',{attrs:{"credToSync":_vm.syncSidePanelCred},on:{"closeSyncSidePanel":_vm.closeSyncSidePanel}}):_vm._e(),(_vm.legoSidePanelOpen)?_c('LegoSidePanel',{attrs:{"kitId":_vm.legoSidePanelKitId,"directories":_vm.directories},on:{"close":function($event){_vm.legoSidePanelOpen = false;
      _vm.legoSidePanelKitId = null;
      _vm.legoSidePanelLegoPath = null;},"emitKitDollUpdate":_vm.updateLego,"emitCreatedDirectory":_vm.handleLegoSidePanelDirRefresh}}):_vm._e(),(_vm.isEditDirectoryModalActive)?_c('EditDirectoryModal',{attrs:{"isEditDirectoryModalActive":_vm.isEditDirectoryModalActive,"directoryId":_vm.editDirectoryId,"directoryName":_vm.editDirectoryName},on:{"closeEditDirectoryModal":_vm.handleCloseEditDirectoryModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }